






















































































































































import Vue from 'vue';
import { formatDateTime } from '@/utils/tools';
import { mapGetters } from 'vuex';
import SessionWrapper from '@/components/Viewer/SessionWrapper.vue';
import ThemeModel from '@/dtos/theme';

export default Vue.extend({
  components: { SessionWrapper },
  data() {
    return {
      now: new Date(),
    };
  },

  computed: {
    ...mapGetters({
      userId: 'auth/getUserId',
      sessionQuestions: 'viewer/getSessionQuestions',
      activeSession: 'viewer/getActiveSession',
      sessionTheme: 'viewer/getSessionTheme',
    }),
    theme(): ThemeModel {
      return this.sessionTheme as ThemeModel;
    },
    questions(): [] {
      if (this.userId && this.sessionQuestions(this.userId)) {
        return this.sessionQuestions(this.userId);
      }
      return [];
    },
  },
  methods: {
    formatDateTime(input: any): string {
      return formatDateTime(input);
    },
    submitUpvote(id: string) {
      // Check if user has already upvoted question by id
      const ref = `question-${id}`;
      if (localStorage.getItem(ref) !== 'upvoted') {
        localStorage.setItem(ref, 'upvoted');
        this.$store.dispatch('viewer/submitQuestionUpvote', id);
      }
    },
  },
});
